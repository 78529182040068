import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

export type TopState = {
  privacyReadFlg: boolean;
  authorizedFlg: boolean;
};

const initialState: TopState = {
  privacyReadFlg: false,
  authorizedFlg: false
};

export const topSlice = createSlice({
  name: 'topSlice',
  initialState,
  reducers: {
    readPrivacy: (state) => {
      Object.assign(state, { privacyReadFlg: true });
    },
    authorize: (state) => {
      Object.assign(state, { authorizedFlg: true });
    }
  }
});

export const selectTopState = (state: RootState): TopState => state.topReducer;
