import { VFC } from 'react';
import styles from './SelectBoxForm.module.scss';

type SelectBoxOption = {
  value: number | string;
  label: string;
};
type SelectBoxProps = {
  value: number | string;
  target: string;
  SelectBoxOptions: SelectBoxOption[];
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
};

export const SelectBoxForm: VFC<SelectBoxProps> = (props) => {
  return (
    <select value={props.value} className={styles['select-box']} name={props.target} onChange={props.onChange}>
      {props.SelectBoxOptions.map((o, i) => (
        <option key={i} value={o.value}>
          {o.label}
        </option>
      ))}
    </select>
  );
};
