import axios from 'axios';
import { InputInfoState } from '../store/inputInfo';
import camera from '../assets/images/common/photo_camera_black_48dp.svg';

// APIの実行
export const registGeneralForm = async (
  state: InputInfoState,
  param: { requireDocumentPath: string; cashCardPath: string; claimFlg: string; registFlg: boolean }
): Promise<{ status: number; message: string }> => {
  const data = {
    webBillingNumber: state.webBillingNumber,
    accidentDate: state.accidentDate,
    accidentSituation: state.accidentSituation,
    requireDocumentUrls: param.requireDocumentPath,
    beneficiary: state.beneficiary,
    beneficiaryName: state.beneficiaryName,
    postalCode: state.postalCodeFirst + state.postalCodeSecond,
    address: state.address,
    insuranceReceiptAccountInfo: state.insuranceReceiptAccountInfo,
    cashCardUrl: param.cashCardPath,
    bankName: state.bankName,
    bankType: state.bankType,
    branchName: state.branchName,
    branchType: state.branchType,
    accountType: state.accountType,
    branchNumber: state.branchNumber,
    accountNumber: state.accountNumber,
    passbookSign: state.passbookSign,
    passbookNumber: state.passbookNumber,
    accountHolder: state.accountHolder,
    note: state.note,
    claimFlg: param.claimFlg,
    cashCardImgUploadFlg: state.cashCardUrl.src !== camera,
    accountInfoViewFlg: state.accountInfoViewFlg,
    registFlg: param.registFlg,
    insuranceType: '0'
  };
  let result = { status: 0, message: '' };
  // サーバーチェック及び登録処理
  const config = { headers: { Authorization: sessionStorage.getItem('Authorization') || '' } };
  await axios
    .post(`${process.env.REACT_APP_API_URL}/registGeneralForm`, data, config)
    .then((res) => {
      result = { status: 200, message: res.data.message };
    })
    .catch((error) => {
      result = { status: error.response.status, message: error.response.data.message };
    });
  return result;
};

// 署名付きURL発行処理
export const getSignedUrl = async (
  webBillingNumber: string,
  directoryName: string,
  fileList: any[]
): Promise<{ status: number; message: string; url: string[]; path: string }> => {
  const fileInfoList = [];
  for (const fileObj of fileList) {
    fileInfoList.push({ name: fileObj.file.name, type: fileObj.file.type });
  }
  const data = { webBillingNumber: webBillingNumber, directoryName: directoryName, fileList: fileInfoList };
  let result = { status: 0, message: '', url: [''], path: '' };
  // サーバーチェック及び登録処理
  const config = { headers: { Authorization: sessionStorage.getItem('Authorization') || '' } };
  await axios
    .post(`${process.env.REACT_APP_API_URL}/getSignUrl`, data, config)
    .then((res) => {
      result = { status: 200, message: res.data.message, url: res.data.url, path: res.data.path };
    })
    .catch((error) => {
      result = { status: error.response.status, message: error.response.data.message, url: [''], path: '' };
    });
  return result;
};

// S3内フォルダ削除処理
export const deleteS3Object = async (path: string): Promise<{ status: number }> => {
  const data = { path: path };
  let result = { status: 0 };
  // 削除処理
  const config = { headers: { Authorization: sessionStorage.getItem('Authorization') || '' } };
  await axios
    .post(`${process.env.REACT_APP_API_URL}/deleteS3Object`, data, config)
    .then(() => {
      result = { status: 200 };
    })
    .catch(() => {
      result = { status: 500 };
    });
  return result;
};
