import { VFC } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import styles from './Guideline.module.scss';
import { CommonButton } from '../components/CommonButton';
import { PageTitle } from '../components/PageTitle';
import { InfoArea } from '../components/InfoArea';
import { selectAccidentState } from '../store/accident';

export const Guideline: VFC = () => {
  const navigate = useNavigate();
  const claimFlg = useSelector(selectAccidentState).claim_flg;
  return (
    <div className={styles['wrap']}>
      <PageTitle value="ご案内"></PageTitle>
      <div className={styles['contents']}>
        <InfoArea title="ご案内">
          {claimFlg == '0' && (
            <div>
              ・所要時間は約5分です。<br></br>
              ・入力の途中で中断することはできませんので、ご注意ください。<br></br>
              ・お手続き内容は以下の通りです。<br></br>
              <div className={styles['guideline-paragraph']}>
                ①被害内容の確認出来る資料（写真や見積）のご登録<br></br>
                ②保険金受取口座情報のご入力お手続き完了後、ショートメールまたはメールにて事故受付完了のご案内をいたします
              </div>
            </div>
          )}
          {claimFlg == '1' && (
            <div>
              ・ご連絡を受け付け後、保険金サービス拠点の担当者から電話や書面等でご連絡いたします。<br></br>
              <br></br>
              ※弊社営業時間（平日9：00～17：00）外に受け付けた場合は、翌営業日以降のご連絡、発送となります。<br></br>
              ※お急ぎの場合は、「損保ジャパン事故サポートセンター（0120-256-110）」への電話によるご連絡をお願いいたします。<br></br>
              ※ご加入の損害保険代理店への事故のご連絡をご希望の場合、損害保険代理店の連絡先は保険証券またはWeb証券にてご確認ください。
              <br></br>
              ※万が一、担当者からの連絡がない場合は、誠に申し訳ございませんが、お客様より損保ジャパン事故サポートセンター（0120-256-110）までご連絡をお願いいたします。
            </div>
          )}
        </InfoArea>
      </div>
      <div className={styles['wrap-btn']}>
        <CommonButton
          label="戻る"
          onClick={() => {
            navigate('/');
          }}
          color={'#fff'}
          backGroundColor={'#75757c'}
        ></CommonButton>
      </div>
    </div>
  );
};
