import './App.scss';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useWindowSize } from 'react-use';
import { Top } from './pages/Top';
import { Guideline } from './pages/Guideline';
import { Privacy } from './pages/Privacy';
import { InputInfo } from './pages/InputInfo';
import { ReceptionComp } from './pages/ReceptionComp';
import { ConfirmInfo } from './pages/ConfirmInfo';
import { useEffect, VFC } from 'react';
import { Loading } from './components/Loading';
import { accidentSlice, AccidentState } from './store/accident';
import { topSlice, selectTopState } from './store/top';
import { ErrorPage } from './pages/ErrorPage';
import { errorSlice } from './store/error';
/**
 * 有効な個別アクセスURLか検証する。
 * @param {JSX.Element} { children } - URLが有効だった場合に表示する画面
 * @return {JSX.Element} { children } - 検証結果に応じて表示される画面
 */
function ValidateUrl({ children }: { children: JSX.Element }): JSX.Element {
  // すでに有効な個別アクセスであるか検証済みの時
  const authorizedFlg = useSelector(selectTopState).authorizedFlg;
  if (authorizedFlg) return children;
  // URLクエリパラメータを取得
  const queryParams = new URLSearchParams(window.location.search);
  if (queryParams.has('p')) {
    const request = new XMLHttpRequest();
    const value = queryParams.get('p') as string;
    // 取得したURLクエリパラメータは暗黙的にURIデコードされているため、URIエンコードし直す
    const encodedValue = encodeURIComponent(value);
    const url = `${process.env.REACT_APP_API_URL}/validateUrl?p=` + encodedValue;
    // APIを実行
    request.open('GET', url, false); // `false` で同期リクエストになる
    request.send(null); // `null` でリクエストボディがないことを表す
    if (request.status === 200) {
      // 後続の処理用に事故情報, 検証済みフラグをstoreに保存、Authorizationトークンをセッションストレージに保存
      const res: AccidentState = JSON.parse(request.responseText);
      const dispatch = useDispatch();
      dispatch(accidentSlice.actions.updateAccident(res));
      sessionStorage.setItem('Authorization', value);
      dispatch(topSlice.actions.authorize());
      return children;
    } else if (JSON.parse(request.responseText).message === '保険金請求情報は既に登録されています。') {
      const dispatch = useDispatch();
      dispatch(
        errorSlice.actions.updateErrorPage({
          errorTitle: JSON.parse(request.responseText).message,
          errorMessage:
            'お客さまは既に保険金請求情報を登録されています。\n情報の訂正や追加登録をされる場合は、直接担当者までお知らせください。\nなお担当者の連絡先がお分かりにならない場合は下記よりご連絡ください。',
          urlPageName: '損保ジャパン公式ホームページ：',
          urlLink: 'https://www.sompo-japan.co.jp/covenanter/acontact/'
        })
      );
      return <Navigate to="/systemError" />;
    } else {
      const dispatch = useDispatch();
      dispatch(
        errorSlice.actions.updateErrorPage({
          errorTitle: JSON.parse(request.responseText).message,
          errorMessage: 'SMSまたはメールでご案内したURLから再度アクセスをお願いいたします。'
        })
      );
      return <Navigate to="/systemError" />;
    }
  } else {
    const dispatch = useDispatch();
    dispatch(
      errorSlice.actions.updateErrorPage({
        errorTitle: '不正なアクセスです。',
        errorMessage: 'SMSまたはメールでご案内したURLから再度アクセスをお願いいたします。'
      })
    );
    return <Navigate to="/systemError" />;
  }
}

/**
 * すでに有効な個別アクセスと検証済みか
 * @param {JSX.Element} { children } - URLが有効だった場合に表示する画面
 * @return {JSX.Element} { children } - 検証結果に応じて表示される画面
 */
function RequireAuth({ children }: { children: JSX.Element }): JSX.Element {
  const authorizedFlg = useSelector(selectTopState).authorizedFlg;
  if (authorizedFlg) {
    return children;
  } else {
    const dispatch = useDispatch();
    dispatch(
      errorSlice.actions.updateErrorPage({
        errorTitle: '不正なアクセスです。',
        errorMessage: 'SMSまたはメールでご案内したURLから再度アクセスをお願いいたします。'
      })
    );
    return <Navigate to="/systemError" />;
  }
}
/**
 * 存在しないパスはエラー画面を表示する
 * @param {JSX.Element} { children } - URLが有効だった場合に表示する画面
 * @return {JSX.Element} { children } - 正しくないパスで接続した場合に表示する画面
 */
function PathError({ children }: { children: JSX.Element }): JSX.Element {
  const [getUserPathList] = ['/', '/guideline', '/privacy', '/samplePage', '/inputInfo', '/receptionComp', '/confirmInfo'];
  if (location.pathname === getUserPathList) {
    return children;
  } else {
    const dispatch = useDispatch();
    dispatch(
      errorSlice.actions.updateErrorPage({
        errorTitle: '不正なアクセスです。',
        errorMessage: 'SMSまたはメールでご案内したURLから再度アクセスをお願いいたします。'
      })
    );
    return <Navigate to="/systemError" />;
  }
}
const App: VFC = () => {
  const { width, height } = useWindowSize(); // ブラウザサイズをリアルタイムに取得してサイズが変更された際にスタイルを再計算する
  const setAppMinHeight = (): void => {
    // 100vhだとiosのみ表示されるアドレスバーの考慮ができないためwindow.innerHeightで実寸サイズを設定
    const app = document.getElementById('app');
    if (app) {
      app.style.minHeight = window.innerHeight + 'px';
    }
  };
  useEffect(() => {
    setAppMinHeight();
  }, []);
  useEffect(() => {
    setAppMinHeight();
  }, [width, height]);
  return (
    <div className="App" id={'app'}>
      <Loading></Loading>
      <header className="App-header">
        <p className="logo">
          <a href="https://www.sompo-japan.co.jp">
            <span>損保ジャパン</span>
          </a>
        </p>
      </header>
      <div className="main">
        <Routes>
          <Route
            path="/"
            element={
              <ValidateUrl>
                <Top />
              </ValidateUrl>
            }
          ></Route>
          <Route
            path="/guideline"
            element={
              <RequireAuth>
                <Guideline />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/privacy"
            element={
              <RequireAuth>
                <Privacy />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/inputInfo"
            element={
              <RequireAuth>
                <InputInfo />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/receptionComp"
            element={
              <RequireAuth>
                <ReceptionComp />
              </RequireAuth>
            }
          ></Route>
          <Route
            path="/confirmInfo"
            element={
              <RequireAuth>
                <ConfirmInfo />
              </RequireAuth>
            }
          ></Route>
          <Route path="/systemError" element={<ErrorPage />}></Route>
          <Route
            path="/*"
            element={
              <PathError>
                <ErrorPage />
              </PathError>
            }
          ></Route>
        </Routes>
      </div>
      <footer className="App-footer">
        <p>
          <small>Copyright&copy;2014-2022 Sompo Japan Insurance Inc. All Rights Reserved.</small>
        </p>
      </footer>
    </div>
  );
};

const AppWrapper: VFC = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default AppWrapper;
