import styles from './ErrorPage.module.scss';
import { VFC } from 'react';
import { useSelector } from 'react-redux';
import { selectErrorState } from '../store/error';
import { PageTitle } from '../components/PageTitle';
import sompo from '../assets/images/common/sompo-logo.jpg';

export const ErrorPage: VFC = () => {
  const selector = useSelector(selectErrorState);

  return (
    <>
      <div className={styles['wrap']}>
        <PageTitle value={' '}></PageTitle>
        <div className={styles['contents']}>
          <div className={styles['image']}>
            <img src={sompo} alt="sompo-logo" height="300" />
          </div>
          <div className={styles['message']}>{selector.errorTitle}</div>
          <div className={styles['message-detail']}>
            <p>{selector.errorMessage}</p>
            {selector.urlLink && (
              <p>
                {selector.urlPageName}
                <a href={selector.urlLink} target="_blank" rel="noopener noreferrer" style={{ color: 'blue' }}>
                  {selector.urlLink}
                </a>
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};
