import { configureStore } from '@reduxjs/toolkit';
import { inputInfoSlice } from './inputInfo';
import { loadingSlice } from './loading';
import { topSlice } from './top';
import { accidentSlice } from './accident';
import { errorSlice } from './error';

export const store = configureStore({
  reducer: {
    inputInfoReducer: inputInfoSlice.reducer,
    loadingReducer: loadingSlice.reducer,
    topReducer: topSlice.reducer,
    accidentReducer: accidentSlice.reducer,
    errorReducer: errorSlice.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [
          'inputInfoSlice/updateInputInfo',
          'loadingSlice/loadingOpen',
          'loadingSlice/loadingClose',
          'accidentSlice/updateAccident',
          'topSlice/readPrivacy',
          'topSlice/authorize',
          'errorSlice/updateErrorPage'
        ]
      }
    })
});

export type RootState = ReturnType<typeof store.getState>;
