import { VFC } from 'react';
import { Backdrop, CircularProgress } from '@mui/material';
import styles from './Loading.module.scss';
import { useSelector } from 'react-redux';
import { selectLoadingState } from '../store/loading';

export const Loading: VFC = () => {
  const loading = useSelector(selectLoadingState);
  return (
    <Backdrop open={loading.loadingFlg} className={styles['back-style']}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
