// date型をstring型にコンバート
export const convertStr = (value: Date): any => {
  if (!Number.isNaN(value.getTime())) {
    const year = value.getFullYear();
    // 月だけ+1すること
    const month = 1 + value.getMonth();
    const day = value.getDate();
    // 表示用で0パディングしたストリング型の日付に変更
    const accidentDateStr = year + '/' + ('0' + month).slice(-2) + '/' + ('0' + day).slice(-2);
    return accidentDateStr;
  }
  return 'invalidDate';
};
