import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import { Loading } from '../util/type';

export type LoadingState = Loading;

const initialState: LoadingState = {
  loadingFlg: false
};

export const loadingSlice = createSlice({
  name: 'loadingSlice',
  initialState,
  reducers: {
    loadingOpen: (state) => {
      Object.assign(state, { loadingFlg: true });
    },
    loadingClose: (state) => {
      Object.assign(state, { loadingFlg: false });
    }
  }
});

export const selectLoadingState = (state: RootState): LoadingState => state.loadingReducer;
