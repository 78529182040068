import { VFC } from 'react';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import styles from './TextForm.module.scss';
import { Validation } from '../util/type';

export type TextFormProp = {
  maxlength: number;
  value: string;
  target: string;
  width?: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  register?: UseFormRegister<FieldValues>;
  validation?: Validation;
};
export const TextForm: VFC<TextFormProp> = (props) => {
  if (props.register) {
    const target = props.register(props.target, props.validation);
    return (
      <input
        autoComplete="off"
        id={props.target}
        maxLength={props.maxlength}
        className={styles['input-text']}
        type="text"
        value={props.value}
        style={{ width: props.width || '100%' }}
        {...target}
        onChange={(e) => {
          target.onChange(e);
          props.onChange(e);
        }}
      ></input>
    );
  } else {
    return (
      <input
        autoComplete="off"
        id={props.target}
        maxLength={props.maxlength}
        className={styles['input-text']}
        type="text"
        value={props.value}
        style={{ width: props.width || '100%' }}
        name={props.target}
        onChange={props.onChange}
      ></input>
    );
  }
};
