import styles from './ReceptionComp.module.scss';
import { PageTitle } from '../components/PageTitle';
import { VFC } from 'react';
import { selectAccidentState } from '../store/accident';
import { useSelector } from 'react-redux';

export const ReceptionComp: VFC = () => {
  const claimFlg = useSelector(selectAccidentState).claim_flg;

  return (
    <div className={styles['wrap']}>
      <PageTitle value="保険金請求フォーム"></PageTitle>
      <div className={styles['contents']}>
        <div className={styles['message']}>ご請求を受け付けました。</div>
        <div className={styles['message-detail']}>
          <p>ご入力いただき、ありがとうございます。</p>
          <p>お客さまの電話番号もしくはメールアドレス宛に受付確認メールを送信しております。</p>
          <p>
            今後の対応につきましては、当社から順次ご案内させていただきます。その際、ご入力いただいた内容についてあらためてお伺いする場合もございます。
          </p>
          {claimFlg == '0' && (
            <p>
              なお、土日・祝日の前後、年末年始等の期間、および自然災害発生時には当社からのご案内が遅れる場合がありますのであらかじめご了承くださいますようお願い申し上げます。
            </p>
          )}
          {claimFlg == '1' && (
            <p>
              なお、順次ご案内をさせて頂いておりますので、当社からのご案内が遅れる場合がありますがあらかじめご了承くださいますようお願い申し上げます。
            </p>
          )}
          <p>※当社の営業時間は、午前９時から午後５時まで（土日・祝日、１２月３１日～１月３日は除く）となります。</p>
          <div className={styles['hyperlink']}>
            <a href="https://www.sompo-japan.co.jp/contact/">お問い合わせページへ</a>
          </div>
        </div>
        <div className={styles['notes']}>画面右上の「×」ボタンをタップして終了してください。</div>
      </div>
    </div>
  );
};
