import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

export type AccidentState = {
  web_billing_number: number;
  policy_number: string;
  accident_date: Date;
  temporary_accident_number: string;
  insurance_type: string;
  sp_disaster_code: string;
  sc_code: string;
  user_id: string;
  customer_attribute: string;
  customer_name: string;
  contact_method: string;
  telephone_number: string;
  mail_address: string;
  claim_flg: string;
  status: string;
  require_documents: string;
  claim_notification_address: string;
  insert_user: string;
  insert_date: Date;
  update_user: string;
  update_date: Date;
};

const initialState: AccidentState = {
  web_billing_number: 0,
  policy_number: '',
  accident_date: new Date(),
  temporary_accident_number: '',
  insurance_type: '',
  sp_disaster_code: '',
  sc_code: '',
  user_id: '',
  customer_attribute: '',
  customer_name: '',
  contact_method: '',
  telephone_number: '',
  mail_address: '',
  claim_flg: '',
  status: '',
  require_documents: '',
  claim_notification_address: '',
  insert_user: '',
  insert_date: new Date(),
  update_user: '',
  update_date: new Date()
};

export const accidentSlice = createSlice({
  name: 'accidentSlice',
  initialState,
  reducers: {
    updateAccident: (state, action) => {
      Object.assign(state, action.payload);
    }
  }
});

export const selectAccidentState = (state: RootState): AccidentState => state.accidentReducer;
