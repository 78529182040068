import { VFC } from 'react';
import { useDispatch } from 'react-redux';
import styles from './Privacy.module.scss';
import { PageTitle } from '../components/PageTitle';
import { CommonButton } from '../components/CommonButton';
import { useNavigate } from 'react-router-dom';
import { topSlice } from '../store/top';
import { InfoArea } from '../components/InfoArea';

export const Privacy: VFC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClickBtn: any = () => {
    dispatch(topSlice.actions.readPrivacy());
    navigate('/');
  };
  return (
    <div className={styles['wrap']}>
      <PageTitle value="個人情報の取扱いに関する事項"></PageTitle>
      <div className={styles['contents']}>
        <div className={styles['description']}>
          <p>※ブラウザの「戻る」「進む」ボタンを使用しますと、正しく動作しない場合がございますので、ご注意ください。</p>
        </div>
        <InfoArea title="個人情報の取扱いに関する事項">
          <div>
            当社は、本サービスのご利用にあたってお客さまからお知らせいただくお客さま情報を、本サービスの履行のために利用するほか、保険引受・支払いの判断、付帯サービスの提供、保険・金融商品または各種サービスの案内・提供、アンケート実施、等の業務目的のために利用することがあります。
            <br></br>
            <br></br>
            当社の個人情報保護宣言に定める個人情報の利用目的は
            <a
              className={styles['a']}
              href="https://www.sompo-japan.co.jp/private_information/detail/"
              target="_blank"
              rel="noopener noreferrer"
            >
              こちら
            </a>
            です。<br></br>
            その他に、お客さまが本サービスを保険契約にご加入のために利用される場合、または既にご加入いただいている保険契約に関するサービスの提供を受けるために利用される場合において、当社は業務上必要とする範囲で、次の1から4（特約火災保険（注）にご加入のお客さまは次の1から3）に掲げるとおり、お客さま情報を取得・利用・提供または登録することがあります。
            <br></br>
            <br></br>
            <table className={styles['table']}>
              <tbody className={styles['tbody']}>
                <tr className={styles['tr']}>
                  <td className={styles['td']}></td>
                  <td className={styles['td']}>
                    当社が、本サービスの履行のために、業務委託先（保険代理店を含みます）、保険仲立人、医療機関、保険金の請求・支払いに関する関係先、グループ企業や提携先企業、等に提供を行い、またはこれらの者から提供を受けること。
                  </td>
                </tr>
                <tr className={styles['tr']}>
                  <td className={styles['td']}></td>
                  <td className={styles['td']}>
                    当社が、保険制度の健全な運営のために、社団法人日本損害保険協会、他の損害保険会社等に提供もしくは登録を行い、またはこれらの者から提供を受けること。
                  </td>
                </tr>
                <tr className={styles['tr']}>
                  <td className={styles['td']}></td>
                  <td className={styles['td']}>
                    当社が、再保険契約の締結や再保険金等の受領のために、再保険会社等に提供を行うこと。（再保険会社等から他の再保険会社等への提供を含みます）
                  </td>
                </tr>
                <tr className={styles['tr']}>
                  <td className={styles['td']}></td>
                  <td className={styles['td']}>
                    当社が、グループ企業や提携先企業に提供を行い、当該企業がその取扱う金融商品等の案内または提供を行うこと。
                  </td>
                </tr>
              </tbody>
            </table>
            なお、保健医療等の特別な非公開情報（センシティブ情報）については、保険業法施行規則により限定された目的以外の目的に利用しません。
            <br></br>
            当社の個人情報保護宣言、グループ企業や提携先企業については、当社の公式ウェブサイト内をご覧ください。<br></br>
            <ul className={styles['ul']}>
              <li className={styles['li']}>
                <a
                  className={styles['a']}
                  href="https://www.sompo-japan.co.jp/private_information/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  個人情報保護宣言
                </a>
              </li>
              <li className={styles['li']}>
                <a className={styles['a']} href="https://www.sompo-japan.co.jp/company/group/" target="_blank" rel="noopener noreferrer">
                  グループ会社のご案内
                </a>
              </li>
            </ul>
            <br></br>
          </div>
        </InfoArea>
        <div className={styles['wrap-btn']}>
          <CommonButton label="確認しました" onClick={handleClickBtn} color={'#fff'} backGroundColor={'#75757c'}></CommonButton>
        </div>
      </div>
    </div>
  );
};
