import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';
import camera from '../assets/images/common/photo_camera_black_48dp.svg';

export type InputInfoState = {
  webBillingNumber: number;
  accidentDate: string;
  accidentSituation: string;
  requireDocumentUrls: [
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string }
  ];
  beneficiary: string;
  beneficiaryName: string;
  postalCodeFirst: string;
  postalCodeSecond: string;
  address: string;
  insuranceReceiptAccountInfo: string;
  cashCardUrl: { file: File | null; src: string };
  bankName: string;
  bankType: string;
  branchName: string;
  branchType: string;
  accountType: string;
  branchNumber: string;
  accountNumber: string;
  passbookSign: string;
  passbookNumber: string;
  accountHolder: string;
  note: string;
  accountInfoViewFlg: boolean;
  fileViewFlgList: [boolean, boolean, boolean];
  isRequireDocumentUrls: (
    arg: any[]
  ) => arg is [
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string }
  ];
};
const initialState: InputInfoState = {
  webBillingNumber: 0,
  accidentDate: '',
  accidentSituation: '',
  requireDocumentUrls: [
    { file: null, src: camera },
    { file: null, src: camera },
    { file: null, src: camera },
    { file: null, src: camera },
    { file: null, src: camera },
    { file: null, src: camera },
    { file: null, src: camera },
    { file: null, src: camera },
    { file: null, src: camera },
    { file: null, src: camera },
    { file: null, src: camera },
    { file: null, src: camera },
    { file: null, src: camera },
    { file: null, src: camera },
    { file: null, src: camera },
    { file: null, src: camera },
    { file: null, src: camera },
    { file: null, src: camera },
    { file: null, src: camera },
    { file: null, src: camera }
  ],
  beneficiary: '0',
  beneficiaryName: '',
  postalCodeFirst: '',
  postalCodeSecond: '',
  address: '',
  insuranceReceiptAccountInfo: '0',
  cashCardUrl: { file: null, src: camera },
  bankName: '',
  bankType: '0',
  branchName: '',
  branchType: '0',
  accountType: '0',
  branchNumber: '',
  accountNumber: '',
  passbookSign: '',
  passbookNumber: '',
  accountHolder: '',
  note: '',
  accountInfoViewFlg: true,
  fileViewFlgList: [false, false, false],
  isRequireDocumentUrls: (
    arg: any[]
  ): arg is [
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string },
    { file: File | null; src: string }
  ] => arg.length === 20 && arg.every((a) => typeof a === 'object')
};

export const inputInfoSlice = createSlice({
  name: 'inputInfoSlice',
  initialState,
  reducers: {
    updateInputInfo: (state, action) => {
      Object.assign(state, action.payload);
    }
  }
});

export const selectInputInfoState = (state: RootState): InputInfoState => state.inputInfoReducer;
