import { ReactNode, VFC } from 'react';
import styles from './InfoArea.module.scss';

type Props = {
  children: ReactNode | ReactNode[];
  title: string;
  requireStatus?: number;
};
export const InfoArea: VFC<Props> = (props) => {
  return (
    <div className={styles['area-wrap']}>
      <div className={styles['area-title']}>
        <p>◆{props.title}</p>
        {props.requireStatus == 1 && (
          <div className={`${styles['mark']} ${styles['require']}`}>
            <span>必須</span>
          </div>
        )}
        {props.requireStatus == 2 && (
          <div className={`${styles['mark']}`}>
            <span>任意</span>
          </div>
        )}
      </div>
      <div className={styles['area-contents']}>{props.children}</div>
    </div>
  );
};
