import { VFC } from 'react';
import styles from './RadioBtnForm.module.scss';

type RadioBtnOption = {
  value: number | string;
  label: string;
};
type RadioBtnProps = {
  value: number | string | null;
  target: string;
  RadioBtnOptions: RadioBtnOption[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const RadioBtnForm: VFC<RadioBtnProps> = (props) => {
  return (
    <div className={styles['radio-wrap']}>
      {props.RadioBtnOptions.map((o) => {
        return (
          <div key={`${props.target}-${o.value}`}>
            <input
              className={styles['radio-btn']}
              type="radio"
              id={`${props.target}-${o.value}`}
              value={o.value}
              checked={props.value === o.value}
              name={props.target}
              onChange={props.onChange}
            ></input>
            <label className={styles['radio-label']} htmlFor={`${props.target}-${o.value}`}>
              {o.label}
            </label>
          </div>
        );
      })}
    </div>
  );
};
