/* eslint-disable @typescript-eslint/indent */
import { useLayoutEffect, useState, VFC } from 'react';
import { PageTitle } from '../components/PageTitle';
import { CommonButton } from '../components/CommonButton';
import { useDispatch, useSelector } from 'react-redux';
import { selectInputInfoState } from '../store/inputInfo';
import styles from './ConfirmInfo.module.scss';
import { ItemDisp } from '../components/ItemDisp';
import { useNavigate } from 'react-router-dom';
import {
  accountTypeList,
  bankTypeList,
  BeneficiarySelectOptions,
  branchTypeList,
  InsuranceReceiptAccountInfoOptionsList
} from '../util/const';
import camera from '../assets/images/common/photo_camera_black_48dp.svg';
import file from '../assets/images/common/common_file_icon.svg';
import { InfoArea } from '../components/InfoArea';
import { selectAccidentState } from '../store/accident';
import { CheckBoxForm } from '../components/CheckBoxForm';
import { CommonDialog } from '../components/CommonDialog';
import { deleteS3Object, getSignedUrl, registGeneralForm } from '../hooks/useInputInfo';
import axios from 'axios';
import { loadingSlice } from '../store/loading';
import { errorSlice } from '../store/error';

export const ConfirmInfo: VFC = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const selector = useSelector(selectInputInfoState);
  const claimFlg = useSelector(selectAccidentState).claim_flg;
  const [confirmCheckFlg, setConfirmCheckFlg] = useState(false);
  const [open, setOpen] = useState(false);
  // APIエラーメッセージ切り替え用state
  const [apiError, setApiError] = useState('');
  useLayoutEffect(() => {
    window.scroll({ top: 0, behavior: 'smooth' });
  }, []);

  const handleClick = async (): Promise<void> => {
    // ローディングスピナーを表示する
    dispatch(loadingSlice.actions.loadingOpen());
    setOpen(false);
    const param = { requireDocumentPath: '', cashCardPath: '', claimFlg: claimFlg, registFlg: true };
    const newDocuments = selector.requireDocumentUrls.filter((d) => d.file !== null);
    const documentsRes = await getSignedUrl(String(selector.webBillingNumber), 'requiredDocuments', newDocuments);
    // 添付資料用署名付きURL発行及びアップロード処理
    for (const [index, url] of documentsRes.url.entries()) {
      const fileObj = newDocuments[Number(index)].file;
      if (url != null && fileObj != null) {
        if (documentsRes.status === 200) {
          const putRes = await axios.put(url, fileObj, {
            headers: {
              'Content-Type': fileObj.type
            }
          });
          // アップロード処理でエラーが起きた場合すでにアップロードした資材を削除しシステムエラー画面に遷移
          if (putRes.status !== 200) {
            documentS3Delete(documentsRes.path);
            // ローディングスピナーを非表示にする
            dispatch(loadingSlice.actions.loadingClose());
            navigate(`/systemError`);
            return;
          }
        } else if (
          (documentsRes.status === 401 && documentsRes.message === 'Unauthorized') ||
          (documentsRes.status === 403 && documentsRes.message === 'User is not authorized to access this resource')
        ) {
          dispatch(
            errorSlice.actions.updateErrorPage({
              errorTitle: 'セッション情報の確認ができませんでした。',
              errorMessage: 'SMSまたはメールでご案内したURLから再度アクセスをお願いいたします。'
            })
          );
          // ローディングスピナーを非表示にする
          dispatch(loadingSlice.actions.loadingClose());
          navigate(`/systemError`);
          return;
        } else if (
          (documentsRes.status === 403 && documentsRes.message !== 'User is not authorized to access this resource') ||
          documentsRes.status === 500
        ) {
          // ローディングスピナーを非表示にする
          dispatch(loadingSlice.actions.loadingClose());
          navigate(`/systemError`);
          return;
        }
      }
    }
    param.requireDocumentPath = documentsRes.path;
    const res = await registGeneralForm(selector, param);
    // エラーメッセージがある場合はapiExeStatに渡す、 成功時にはメッセージなしで、完了画面に遷移する
    if (res.status === 200) {
      // ローディングスピナーを非表示にする
      dispatch(loadingSlice.actions.loadingClose());
      navigate(`/receptionComp`);
    } else {
      documentS3Delete(documentsRes.path);
      // ローディングスピナーを非表示にする
      dispatch(loadingSlice.actions.loadingClose());
      if (
        (res.status === 401 && res.message === 'Unauthorized') ||
        (res.status === 403 && res.message === 'User is not authorized to access this resource')
      ) {
        dispatch(
          errorSlice.actions.updateErrorPage({
            errorTitle: 'セッション情報の確認ができませんでした。',
            errorMessage: 'SMSまたはメールでご案内したURLから再度アクセスをお願いいたします。'
          })
        );
        navigate(`/systemError`);
      } else if ((res.status === 403 && res.message !== 'User is not authorized to access this resource') || res.status === 500) {
        navigate(`/systemError`);
      } else {
        setApiError(res.message);
      }
    }
  };
  // S3からアップロードしたファイルの削除処理
  // S3のAPIはフォルダ単位の削除が不可能な為ディレクトリ配下のファイルをkey指定ですべて削除する
  const documentS3Delete = async (path: string): Promise<void> => {
    if (path.length != 0) {
      const arrayPath = path.split(',');
      for (let i = 0; i < arrayPath.length; i++) {
        const res = await deleteS3Object(arrayPath[i]);
        if (res.status !== 200) {
          navigate(`/systemError`);
          break;
        }
      }
    }
  };
  // ダイアログクローズ
  const dialogClose = (): void => {
    setOpen(false);
  };
  const changeCheck = (): void => {
    setConfirmCheckFlg(!confirmCheckFlg);
  };
  return (
    <div className={styles['wrap']}>
      <PageTitle value="保険金請求フォーム"></PageTitle>
      <div className={styles['contents']}>
        <div className={styles['description']}>
          <p>以下の内容で送信いたします。ご確認の上、よろしければ「送信する」ボタンを押してください。</p>
        </div>
        {claimFlg == '1' && (
          <InfoArea title="事故状況">
            <ItemDisp title="●事故日" type={'account'}>
              {selector.accidentDate}
            </ItemDisp>
            <ItemDisp title="●事故状況" type={'accident'}>
              {selector.accidentSituation}
            </ItemDisp>
          </InfoArea>
        )}
        <InfoArea title="資料添付">
          <ItemDisp title="●資料添付" type={'document'}>
            <div
              className={
                styles[
                  selector.requireDocumentUrls.filter((d) => d.src !== camera).length === 1
                    ? 'file-view-area'
                    : selector.requireDocumentUrls.filter((d) => d.src !== camera).length < 9
                    ? 'file-multi-view-area'
                    : 'file-multi-over-view-area'
                ]
              }
            >
              {selector.requireDocumentUrls
                .filter((d) => d.src !== camera)
                .map((d, i) => (
                  <div key={i} className={styles['file-img-area']}>
                    <img key={i} src={d.src} width="60" height="60" alt="preview"></img>
                    <div className={styles['file-name-style']}>{d.src === file && d.file != null ? d.file.name : ''}</div>
                  </div>
                ))}
            </div>
          </ItemDisp>
        </InfoArea>
        <InfoArea title="保険金受取口座情報">
          <ItemDisp title="●受取人" type={'account'}>
            {BeneficiarySelectOptions.find((i) => i.value === selector.beneficiary)?.label ?? ''}
          </ItemDisp>
          {selector.beneficiary === '1' && (
            <>
              <ItemDisp title="●受取人氏名" type={'account'}>
                {selector.beneficiaryName}
              </ItemDisp>
              <ItemDisp title="●郵便番号" type={'account'}>
                {selector.postalCodeFirst + '-' + selector.postalCodeSecond}
              </ItemDisp>
              <ItemDisp title="●住所" type={'account'}>
                {selector.address}
              </ItemDisp>
            </>
          )}
          <ItemDisp title="●保険金受取口座情報" type={'account'}>
            {InsuranceReceiptAccountInfoOptionsList.find((i) => i.value === selector.insuranceReceiptAccountInfo)?.label ?? ''}
          </ItemDisp>
          {selector.accountInfoViewFlg && selector.insuranceReceiptAccountInfo === '0' && (
            <>
              <ItemDisp title="●銀行名" type={'account'}>
                {selector.bankName}
              </ItemDisp>
              <ItemDisp title="●銀行種類" type={'account'}>
                {bankTypeList.find((i) => i.value === selector.bankType)?.label ?? ''}
              </ItemDisp>
              <ItemDisp title="●店舗名" type={'account'}>
                {selector.branchName}
              </ItemDisp>
              <ItemDisp title="●店舗種類" type={'account'}>
                {branchTypeList.find((i) => i.value === selector.branchType)?.label ?? ''}
              </ItemDisp>
              <ItemDisp title="●口座種類" type={'account'}>
                {accountTypeList.find((i) => i.value === selector.accountType)?.label ?? ''}
              </ItemDisp>
              <ItemDisp title="●店番号" type={'account'}>
                {selector.branchNumber}
              </ItemDisp>
              <ItemDisp title="●口座番号" type={'account'}>
                {selector.accountNumber}
              </ItemDisp>
            </>
          )}
          {selector.accountInfoViewFlg && selector.insuranceReceiptAccountInfo === '1' && (
            <>
              <ItemDisp title="●通帳記号" type={'account'}>
                {selector.passbookSign}
              </ItemDisp>
              <ItemDisp title="●通帳番号" type={'account'}>
                {selector.passbookNumber}
              </ItemDisp>
            </>
          )}
          {selector.accountInfoViewFlg && (
            <ItemDisp title="●口座名義人" type={'account'}>
              {selector.accountHolder}
            </ItemDisp>
          )}
        </InfoArea>
        <InfoArea title="その他特記事項">
          <ItemDisp title="" type={'other'}>
            {selector.note}
          </ItemDisp>
        </InfoArea>
        <div className={styles['confirm-check-area']}>
          <CheckBoxForm
            value={confirmCheckFlg}
            onChange={changeCheck}
            label={'損保ジャパンとの保険契約に基づき、保険金を請求します。'}
            target={'confirmCheckFlg'}
          ></CheckBoxForm>
        </div>
        <div className={styles['confirm-btn-area']}>
          <CommonButton
            label="入力内容の修正"
            disabled={false}
            color="#555555"
            backGroundColor="#f9f9f9"
            onClick={() => navigate(`/inputInfo`)}
          ></CommonButton>
          <CommonButton
            label="送信する"
            disabled={!confirmCheckFlg}
            color="#fff"
            backGroundColor="#f1362c"
            onClick={() => setOpen(true)}
          ></CommonButton>
        </div>
        <div className={styles['output-area']}>
          {apiError.length !== 0 && (
            <p id="apiError" role="alert">
              {apiError}
            </p>
          )}
        </div>
      </div>
      <CommonDialog
        open={open}
        title={''}
        content={'送信は一度のみになります。\nこちらの内容で\n送信してよろしいですか。'}
        width={345}
        height={210}
        onClose={dialogClose}
      >
        <CommonButton
          label="キャンセル"
          color={'#555454'}
          backGroundColor={'#e7e7e6'}
          width={'150px'}
          height={'55px'}
          onClick={dialogClose}
        ></CommonButton>
        <CommonButton
          label="OK"
          color={'#fff'}
          backGroundColor={'#00bfff'}
          width={'150px'}
          height={'55px'}
          onClick={handleClick}
        ></CommonButton>
      </CommonDialog>
    </div>
  );
};
