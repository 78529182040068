import { VFC } from 'react';
import styles from './TextAreaForm.module.scss';
import { FieldValues, UseFormRegister } from 'react-hook-form';
import { Validation } from '../util/type';

export type TextAreaFormProp = {
  maxlength?: number;
  value: string;
  target: string;
  onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  register?: UseFormRegister<FieldValues>;
  validation?: Validation;
};
export const TextAreaForm: VFC<TextAreaFormProp> = (props) => {
  if (props.register) {
    const target = props.register(props.target, props.validation);
    return (
      <textarea
        autoComplete="off"
        maxLength={props.maxlength}
        className={styles['input-text']}
        value={props.value}
        {...target}
        onChange={(e) => {
          target.onChange(e);
          props.onChange(e); // our method
        }}
      ></textarea>
    );
  } else {
    return (
      <textarea
        autoComplete="off"
        maxLength={props.maxlength}
        className={styles['input-text']}
        value={props.value}
        name={props.target}
        onChange={props.onChange}
      ></textarea>
    );
  }
};
