import { useEffect, useState, VFC } from 'react';
import styles from './FileUploader.module.scss';
import camera from '../assets/images/common/photo_camera_black_48dp.svg';
import file from '../assets/images/common/common_file_icon.svg';
import { CommonDialog } from '../components/CommonDialog';
import { Button } from '@mui/material';
import { InputInfoState } from '../store/inputInfo';

type FileUploaderProps = {
  id: string;
  file: { file: File | null; src: string };
  inputInfoState?: InputInfoState;
  groupIndex: number;
  onChange: (file: FileList, groupIndex: number) => void;
  deleteDocumentUrl: (index: number) => void;
};

export const FileUploader: VFC<FileUploaderProps> = (props) => {
  const [state, setState] = useState<{ src: string; name: string; type: string }>({
    src: props.file.src,
    name: props.file.file !== null ? props.file.file.name : 'default',
    type: props.file.file !== null ? props.file.file.type : 'image/'
  });
  const [imageState, setImageState] = useState<{ imageWidth: string; imageHeight: string }>({
    imageWidth: '',
    imageHeight: ''
  });
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [mobileFlg, setMobileFlg] = useState<boolean>();
  const [confirmFlg, setConfirmFlg] = useState(false);
  const [fileSelectFlg, setFileSelectFlg] = useState(state.src === camera);

  useEffect(() => {
    setMobileFlg(navigator.userAgent.match(/iPhone|Android.+Mobile/) !== null);
  }, []);
  useEffect(() => {
    setConfirmFlg(state.src !== camera);
  }, [state.src]);

  // 添付ファイルサイズチェック
  const requireDocumentUrlSizeCheck = (fileSize: number): boolean => {
    const sizeLimit = 1024 * 1024 * 30;
    let errFlg = false;
    if (fileSize > sizeLimit) {
      errFlg = true;
    }
    return errFlg;
  };

  // 添付ファイル拡張子チェック
  const requireDocumentsExtensionCheck = (fileType: string): boolean => {
    const allowExts: string[] = ['jpeg', 'jpg', 'png', 'pdf'];
    const fileExtension = fileType.split('/').pop();
    console.log(fileType, fileExtension);
    // 拡張子をチェック
    let errFlg = false;
    if (!fileExtension || !allowExts.includes(fileExtension)) {
      errFlg = true;
    }
    return errFlg;
  };

  // 画像選択時処理
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    setOpenConfirmDialog(false);
    const files = event.target.files;
    if (!files) {
      return;
    }
    let imageSrc = file;
    if (files[0].type.startsWith('image/')) {
      imageSrc = URL.createObjectURL(files[0]);
    }
    setState({
      src: imageSrc,
      name: files[0].name,
      type: files[0].type
    });
    props.onChange(files, props.groupIndex);
    setFileSelectFlg(false);
    if (requireDocumentUrlSizeCheck(files[0].size) || requireDocumentsExtensionCheck(files[0].type)) {
      deletePhoto();
    }
  };

  // オペレーション選択ダイアログ内クリック処理
  const hundleClick = (flg: boolean): void => {
    if (confirmFlg) {
      setOpenConfirmDialog(flg);
      // 確認ダイアログに表示する写真の縦横比を崩さないための処理
      const image = new Image();
      image.src = state.src;
      const width = image.width;
      const height = image.height;
      if (width > height) {
        const width = screen.width - 64;
        setImageState({
          imageWidth: mobileFlg ? width + 'px' : '600px',
          imageHeight: 'auto'
        });
      } else {
        setImageState({
          imageWidth: '100%',
          imageHeight: state.type.startsWith('image/') ? window.innerHeight - 300 + 'px' : '350px'
        });
      }
      setTimeout(() => setFileSelectFlg(true), 1);
    }
  };
  // 画像削除
  const deletePhoto = (): void => {
    setState({
      src: camera,
      name: 'default',
      type: 'image/svg+xml'
    });
    setOpenConfirmDialog(false);
    setFileSelectFlg(true);
    props.deleteDocumentUrl(props.groupIndex);
  };

  // 画像確認ダイアログ内クリック処理
  const confirmDialogHundleClick = (): void => {
    setOpenConfirmDialog(false);
    setFileSelectFlg(false);
  };

  return (
    <>
      <label htmlFor={props.id + props.groupIndex} className={styles['file-uploader-wrap']}>
        <img
          src={state.src}
          width="60"
          height="60"
          id={props.id + props.groupIndex + 'Img'}
          alt="preview"
          onClick={() => hundleClick(true)}
        ></img>
        {fileSelectFlg && (
          <input
            type="file"
            accept=".jpeg,.jpg,.png,.pdf"
            hidden
            style={{ cursor: 'pointer' }}
            id={props.id + props.groupIndex}
            onChange={handleChange}
          />
        )}
      </label>
      {confirmFlg && (
        <CommonDialog open={openConfirmDialog} title={''} width={600} actionOnlyFlg={true} onClose={confirmDialogHundleClick} content={''}>
          <div style={{ display: 'block', textAlign: 'center', width: '100%' }}>
            <img src={state.src} style={{ maxWidth: imageState.imageWidth, height: imageState.imageHeight }} alt="image"></img>
            {!state.type.startsWith('image/') && <div className={styles['file-name-area']}>{state.name}</div>}
            <div className={styles['label-box']} style={{ width: mobileFlg ? '100%' : 600, height: 60 }}>
              <label
                htmlFor={props.id + props.groupIndex}
                className={styles['label-area']}
                style={{ width: mobileFlg ? '100%' : 600, height: 60, position: 'absolute', left: 0 }}
              >
                変更
              </label>
            </div>
            <Button
              className={styles['button-text']}
              id={props.id + props.groupIndex + 'Delete'}
              style={{ width: mobileFlg ? '100%' : 600, height: 60, borderTop: 'solid 1px #e9e9e9' }}
              onClick={deletePhoto}
            >
              削除
            </Button>
            <Button
              className={styles['button-text']}
              style={{ width: mobileFlg ? '100%' : 600, height: 60, borderTop: 'solid 1px #e9e9e9' }}
              onClick={confirmDialogHundleClick}
            >
              キャンセル
            </Button>
          </div>
        </CommonDialog>
      )}
    </>
  );
};
