import React, { ReactNode } from 'react';
import { VFC } from 'react';
import styles from './ItemDisp.module.scss';

export const ItemDisp: VFC<{ title: string; children?: ReactNode | ReactNode[]; type: string }> = React.memo((props) => {
  const align = props.type === 'document' ? 'align-center' : props.type === 'account' ? 'align-right' : 'align-left';
  return (
    <div className={styles['item-frame']}>
      <div id="title">{props.title}</div>
      <div id="contents" className={styles['item-text'] + ' ' + styles[align]}>
        {props.children}
      </div>
    </div>
  );
});
