import { createSlice } from '@reduxjs/toolkit';
import { RootState } from './store';

export type ErrorState = {
  errorTitle: string;
  errorMessage: string;
  urlLink: string;
  urlPageName: string;
};
const initialState: ErrorState = {
  errorTitle: 'システムエラーが発生しました。',
  errorMessage: 'しばらく時間を置いてから再度お試しください。',
  urlPageName: '',
  urlLink: ''
};

export const errorSlice = createSlice({
  name: 'errorSlice',
  initialState,
  reducers: {
    updateErrorPage: (state, action) => {
      Object.assign(state, action.payload);
    }
  }
});

export const selectErrorState = (state: RootState): ErrorState => state.errorReducer;
