import { VFC } from 'react';
import styles from './CheckBoxForm.module.scss';

type CheckBoxProps = {
  value: boolean;
  label?: string;
  target: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const CheckBoxForm: VFC<CheckBoxProps> = (props) => {
  return (
    <div className={styles['check-box-wrap']}>
      <div key={props.target} style={{ display: 'flex', alignItems: 'center' }}>
        <input
          className={styles['check-box-btn']}
          type="checkBox"
          id={props.target}
          checked={props.value}
          name={props.target}
          onChange={props.onChange}
        ></input>
        <label className={styles['check-box-label']} htmlFor={props.target}>
          {props.label}
        </label>
      </div>
    </div>
  );
};
