import React from 'react';
import { VFC } from 'react';
import styles from './CommonButton.module.scss';

type CommonButtonProps = {
  id?: string;
  label: string;
  disabled?: boolean;
  color: string;
  width?: string;
  height?: string;
  radius?: string;
  backGroundColor: string;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

export const CommonButton: VFC<CommonButtonProps> = React.memo((props) => {
  const backGroundColor = props.disabled ? '#e1e2e3' : props.backGroundColor;
  const color = props.disabled ? '#fff' : props.color;

  return (
    <button
      id={props.id}
      className={styles['button-style']}
      disabled={props.disabled || false}
      onClick={props.onClick}
      style={{
        backgroundColor: backGroundColor,
        color: color,
        width: props.width || '200px',
        height: props.height || '50px',
        borderRadius: props.radius || '3px'
      }}
    >
      {props.label}
    </button>
  );
});
