import React, { ReactNode } from 'react';
import { VFC } from 'react';
import styles from './CommonDialog.module.scss';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

type SimpleDialogProps = {
  open: boolean;
  title: string;
  content: string;
  width: number;
  height?: number;
  actionOnlyFlg?: boolean;
  children?: ReactNode | ReactNode[];
  onClose: (value: boolean) => void;
};

export const CommonDialog: VFC<SimpleDialogProps> = React.memo((props) => {
  const { onClose, open } = props;

  const handleClose = (): void => {
    onClose(false);
  };

  return (
    <Dialog
      disableEscapeKeyDown={true}
      PaperProps={{ style: { borderRadius: props.actionOnlyFlg ? 4 : 15, width: props.width, height: props.height } }}
      onClose={handleClose}
      open={open}
    >
      {!props.actionOnlyFlg && (
        <>
          <DialogTitle className={styles['title']}>{props.title}</DialogTitle>
          <DialogContent>
            <DialogContentText className={styles['container']}>{props.content}</DialogContentText>
          </DialogContent>
        </>
      )}
      <DialogActions className={styles['action-area']} style={{ marginBottom: props.actionOnlyFlg ? '0' : '20px' }}>
        {props.children}
      </DialogActions>
    </Dialog>
  );
});
