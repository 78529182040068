export const BeneficiarySelectOptions = [
  {
    value: '0',
    label: 'ご契約者本人または被保険者本人'
  },
  {
    value: '1',
    label: 'その他'
  }
];
export const InsuranceReceiptAccountInfoOptionsList = [
  {
    value: '0',
    label: 'ゆうちょ銀行以外'
  },
  {
    value: '1',
    label: 'ゆうちょ銀行'
  }
];
export const bankTypeList = [
  {
    value: '0',
    label: '銀行'
  },
  {
    value: '1',
    label: '信託銀行'
  },
  {
    value: '2',
    label: '信金'
  },
  {
    value: '3',
    label: '信組'
  },
  {
    value: '4',
    label: '商工中金'
  },
  {
    value: '5',
    label: '労金'
  },
  {
    value: '6',
    label: '農協'
  }
];
export const branchTypeList = [
  {
    value: '0',
    label: '本店'
  },
  {
    value: '1',
    label: '支店'
  },
  {
    value: '2',
    label: '出張所'
  }
];
export const accountTypeList = [
  {
    value: '0',
    label: '普通・総合'
  },
  {
    value: '1',
    label: '当座'
  },
  {
    value: '2',
    label: '貯蓄'
  }
];
