import { useState, VFC } from 'react';
import { useSelector } from 'react-redux';
import styles from './Top.module.scss';
import { PageTitle } from '../components/PageTitle';
import { CommonButton } from '../components/CommonButton';
import { useNavigate } from 'react-router-dom';
import { selectTopState } from '../store/top';
import { InfoArea } from '../components/InfoArea';

export const Top: VFC = () => {
  const navigate = useNavigate();
  const selector = useSelector(selectTopState);
  const [confirmFlg, setConfirmFlg] = useState(false);
  const handleCheckbox: any = () => {
    setConfirmFlg(!confirmFlg);
  };
  return (
    <div className={styles['wrap']}>
      <PageTitle value="保険金のご請求"></PageTitle>
      <div className={styles['contents']}>
        <div className={styles['description']}>
          <p>WEBなら24時間いつでも、カンタン、便利にお手続き頂けます。</p>
        </div>
        <InfoArea title="WEBでの請求について">
          <div className={styles['note-message']}>
            <p>WEBで保険金をご請求いただくにあたり、下記の「ご案内」の内容をご確認ください。</p>
          </div>
          <div className={styles['wrap-btn']}>
            <CommonButton
              label="ご案内"
              onClick={() => {
                navigate('/guideline');
              }}
              color={'#fff'}
              backGroundColor={'#00bfff'}
              width="280px"
              height="80px"
            ></CommonButton>
          </div>
        </InfoArea>
        <InfoArea title="お客さま情報について">
          <div className={styles['note-message']}>
            <p>
              本サービスをご利用いただくにあたり、下記の「個人情報の取扱いに関する事項」に同意いただく必要があります。<br></br>
              下記の「個人情報の取扱いに関する事項」をお読みいただき、「確認しました」をクリックしてください。
            </p>
          </div>
          <div className={styles['wrap-btn']}>
            <CommonButton
              label="個人情報の取扱いに関する事項"
              onClick={() => {
                navigate('/privacy');
              }}
              color={'#fff'}
              backGroundColor={'#00bfff'}
              width="280px"
              height="80px"
            ></CommonButton>
          </div>
          <div className={styles['note-message']}>
            <p className={styles['inner-message']}>
              ご同意いただける場合は下の「同意する」にチェックマークを付け、次へボタンをクリックして進んでください。
            </p>
          </div>
          <div className={styles['wrap-checkbox']}>
            <div className={styles['checkbox']}>
              <input
                checked={confirmFlg}
                disabled={!selector.privacyReadFlg}
                onChange={handleCheckbox}
                type="checkbox"
                id="consent"
              ></input>
              <label htmlFor="consent">同意する。</label>
            </div>
          </div>
        </InfoArea>
        <div className={styles['wrap-btn']}>
          <CommonButton
            label="次へ"
            disabled={!confirmFlg}
            onClick={() => {
              navigate('/inputInfo');
            }}
            color={'#fff'}
            backGroundColor={'#00bfff'}
          ></CommonButton>
        </div>
      </div>
    </div>
  );
};
