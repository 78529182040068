import React, { useEffect } from 'react';
import { VFC } from 'react';
import styles from './PageTitle.module.scss';

export const PageTitle: VFC<{ value: string }> = React.memo((props) => {
  useEffect(() => {
    document.title = props.value;
  }, []);
  return <div className={styles['page-title']}>{props.value}</div>;
});
